import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  anonymous,
  productsImg01,
  productsImg02,
  productsImg03,
  productsImg04
} from "../../asserts/images";
import CustomButton from "../../components/CustomButton";
import "./style.css";
import { useNavigate } from "react-router-dom";
import api from "../../api/axiosInstance";
import { updateEntity } from "../../api/commonServices";
import CustomModal from "../CustomModal";

const BestProducts = ({ className, limit }) => {
  const imgUrl = process.env.REACT_APP_IMG_URL;
  const [showModal, setshowModal] = useState(false);
  const [itemToQuote, setitemToQuote] = useState();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    comment: ""
  });
  const [products, setproducts] = useState([]);
  const navigate = useNavigate();

  const getProducts = async () => {
    try {
      const resp = await api.get("/products");

      console.log("resp", resp);
      setproducts(resp.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    getProducts();
  }, []);
  console.log("products", products);

  const handleRequestQuotes = async (item) => {
    setitemToQuote([item]);
    setshowModal(true)
  };

  const handleSubmit = async () => {
    try {
      const data = {
        ...formData,
        order_items: itemToQuote
      };
      const formDataa = new FormData();
      for (const key in data) {
        if (Array.isArray(data[key])) {
          data[key].forEach((value, index) => {
            formDataa.append(`${key}[${index}]`, JSON.stringify(value));
          });
        } else {
          formDataa.append(key, data[key]);
        }
      }
      const resp = await updateEntity("/request-quote", formDataa);
      alert("Request sent successfully");
    } catch (error) {
      alert("error", error.message);
    } finally {
      setshowModal(false)
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  console.log('gfgffdd',itemToQuote);
  
  return (
    <>
      <section className="best_products_section">
        <Container>
          <Row className={`align-items-end ${className}`}>
            <Col xs={12} sm={12} lg={6}>
              <div>
                <div className="d-flex align-items-center justify-content-start gap-4">
                  <h2 className="primaryHeading">we provide</h2>
                </div>

                <div className="d-flex align-items-center justify-content-center gap-3">
                  <h2 className="primaryHeading">
                    the best <span className="productsBluefont">Products</span>
                  </h2>
                </div>
              </div>
            </Col>

            <Col xs={12} sm={12} lg={6}>
              <div>
                <div className="anonymousImg">
                  <img src={anonymous} alt="anonymous"></img>
                </div>

                <p className="welcome_para m-0">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incid idunt ut labore et dolore magna
                  aliqua. Ut enim ad minim veniam, quis nostrud exe rcitation
                  ullamco laboris nisi ut.
                </p>
              </div>
            </Col>
          </Row>

          <Row className="pt-5 position-relative">
            <div className={`borders ${className}`}></div>

            <div className={`borders02 ${className}`}></div>
            {products.length > 0 &&
              products.slice(0, limit).map((item, id) => {
                return (
                  // <Col xs={10} sm={6} lg={3} className="mx-auto">
                  <Col lg={4} xl={3}>
                    <div className="main_product_card">
                      <div className="main_product_img">
                        <img
                          src={`${imgUrl}/${item.image}`}
                          className="img-fluid"
                          alt="productImg"
                        ></img>
                      </div>

                      <p className="product_card_heading">{item.name}</p>

                      <p className="product_card_detail_para">
                        {item.short_desc}
                      </p>

                      <div>
                        <CustomButton
                          onClick={() => handleRequestQuotes(item)}
                          type="button"
                          className="yellowActionBtn w-100 mb-2 px-2"
                          text="Request A Quote"
                        />
                      </div>

                      <div>
                        <CustomButton
                          type="button"
                          className="w-100 px-2"
                          text="View Product"
                          onClick={() => {
                            console.log("dddd", item.id);

                            navigate(`/product-detail/${item.id}`);
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                );
              })}
          </Row>
        </Container>
        <form onSubmit={handleSubmit}>
          <CustomModal show={showModal} close={() => setshowModal(false)}>
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="name" className="form-label login_label">
                    name <span className="yellow_star">*</span>
                  </label>
                  <input
                    name="name"
                    type="text"
                    className="form-control register_custom_input"
                    id="name"
                    placeholder="Name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="email" className="form-label login_label">
                    email <span className="yellow_star">*</span>
                  </label>
                  <input
                    name="email"
                    type="email"
                    className="form-control register_custom_input"
                    id="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="phone" className="form-label login_label">
                    contact no <span className="yellow_star">*</span>
                  </label>
                  <input
                    name="phone"
                    type="text"
                    className="form-control register_custom_input"
                    id="phone"
                    placeholder="Contact no"
                    value={formData.phone}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="comment" className="form-label login_label">
                    comment <span className="yellow_star">*</span>
                  </label>
                  <textarea
                    name="comment"
                    type="text"
                    className="form-control register_custom_input"
                    id="comment"
                    placeholder="Comment"
                    value={formData.comment}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="m-auto w-100 d-flex justify-content-center">
                  <CustomButton
                   onClick={()=>handleSubmit()}
                    className="user_login_actionBth m-auto"
                    text="Confirm"
                  />
                </div>
              </div>
            </div>
          </CustomModal>
        </form>
      </section>
    </>
  );
};

export default BestProducts;
