import React, { useState, useEffect } from "react";
import CartQuantity from "../CartQuantity";
import { LikeImg01, trash } from "../../asserts/images";
import { imgUrl } from "../../utils/convertToFormData";

import { Link, useNavigate } from "react-router-dom";
import { updateEntity } from "../../api/commonServices";
import CustomButton from "../CustomButton";
import CustomModal from "../CustomModal";

const CartProducts = () => {
  const [feedback, setfeedback] = useState("");
  const [itemToQuote, setitemToQuote] = useState();
  const [cartItems, setCartItems] = useState(
    JSON.parse(localStorage.getItem("cartItem"))
  );
  const navigate = useNavigate();

  const [modalText, setmodalText] = useState("");
  const [successs, setsuccesss] = useState(false);
  const [showModal, setshowModal] = useState(false);

  const [modalHeading, setModalHeading] = useState("");
  const [addState, setAddState] = useState(false);
  const [apiSuccess, setApiSuccess] = useState(false);

  // Form states
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    comment: ""
  });

  // Update local storage whenever cartItems changes
  useEffect(() => {
    localStorage.setItem("cartItem", JSON.stringify(cartItems));
  }, [cartItems]);

  // Handler to update item quantity
  const handleQuantityChange = (index, newQuantity) => {
    const updatedCartItems = [...cartItems];
    updatedCartItems[index].quantity = newQuantity;
    updatedCartItems[index].updatedPrice =
      updatedCartItems[index].price * newQuantity;

    setCartItems(updatedCartItems);
  };

  // Handler to delete an item
  const handleDelete = (index) => {
    const updatedCartItems = cartItems.filter((_, i) => i !== index);
    setCartItems(updatedCartItems);
  };

  const handleRequestQuote = (item) => {
    if (Array.isArray(item)) {
      setitemToQuote(item);
    } else {
      setitemToQuote([item]);
    }
    setshowModal(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      ...formData,
      order_items: itemToQuote,
      total_price: cartItems.reduce(
        (acc, item) => acc + parseInt(item.price),
        0
      )
    };
    console.log("json: ", data);

    const formDataa = new FormData();
    for (const key in data) {
      if (Array.isArray(data[key])) {
        data[key].forEach((value, index) => {
          formDataa.append(`${key}[${index}]`, JSON.stringify(value));
        });
      } else {
        formDataa.append(key, data[key]);
      }
    }

    try {
      console.log("payloadd...", formDataa);

      const response = await updateEntity("/request-quote", formDataa);
      console.log("responseresponseresponse", response);
      localStorage.removeItem("cartItem");
      setshowModal(false);
      setsuccesss(true);

      setmodalText(response.message);
      setfeedback(response.message);
    } catch (error) {
      console.log("error", error);
      setsuccesss(false);
      setmodalText("Error!");
    } finally {
      setTimeout(() => {
        navigate("/shop");
      }, 1000);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="checkout_div text-right">
              {cartItems?.length > 0 && (
                <Link
                  onClick={() => handleRequestQuote(cartItems)}
                  className="checkout_btn"
                >
                  Request a quote
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
      {cartItems?.length > 0 ? (
        <div className="all_cart_items">
          {cartItems?.map((item, index) => (
            <div className="single_cart_product" key={index}>
              <div className="cart_left_part">
                <div className="cart_product_Img">
                  <img
                    src={`${imgUrl}/${item.image}`}
                    alt="cart item image"
                  ></img>
                </div>
                <div className="cart_product_details">
                  <h4 className="cart_product_title">{item.name}</h4>
                  <div>
                    <span className="cart_product_decs text-uppercase">
                      width:
                    </span>
                    <span className="pl-2">{item.width}</span>
                  </div>
                  <div>
                    <span className="cart_product_decs text-uppercase">
                      height:
                    </span>
                    <span className="pl-2">{item.height}</span>
                  </div>
                  <div>
                    <span className="cart_product_decs text-uppercase">
                      mount type:
                    </span>
                    <span className="pl-2">{item.mount_type}</span>
                  </div>
                  <div>
                    <span className="cart_product_decs text-uppercase">
                      room name:
                    </span>
                    <span className="pl-2">{item.room_name}</span>
                  </div>
                  <div>
                    <span className="cart_product_decs text-uppercase">
                      selected warranty option:
                    </span>
                    <span className="pl-2">{item.warranty_options.title}</span>
                  </div>
                  <div>
                    <span className="cart_product_decs text-uppercase">
                      Guarantee fit:
                    </span>
                    <span className="pl-2">
                      {item.measuring_protection_guarantee == 1 ? "Yes" : "No"}
                    </span>
                  </div>{" "}
                  <CartQuantity
                    quantitys={item.quantity}
                    setValue={item.quantity}
                    increase={() =>
                      handleQuantityChange(index, parseInt(item.quantity) + 1)
                    }
                    decrease={() => {
                      handleQuantityChange(
                        index,
                        parseInt(item.quantity) > 1 ? item.quantity - 1 : 1
                      );
                    }}
                  />
                  <div className="cart_product_price_main">
                    <div>
                      <span className="cart_product_price text-uppercase">
                        price:
                      </span>
                      <span className="cart_product_price pl-1">
                        ${item.updatedPrice || item.price}
                      </span>
                    </div>
                    <div onClick={() => handleRequestQuote(item)}>
                      <button className="request_quote_actionBtn">
                        request a free quote over the phone
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cart_right_part">
                <button
                  className="cart_item_delete_btn"
                  onClick={() => handleDelete(index)}
                >
                  <img src={trash} alt="trash"></img>
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-center">No Items In Cart</p>
      )}
      <form onSubmit={handleSubmit}>
        <CustomModal show={showModal} close={() => setshowModal(false)}>
          <div className="row">
            <div className="col-md-12">
              <div className="mb-3">
                <label htmlFor="name" className="form-label login_label">
                  name <span className="yellow_star">*</span>
                </label>
                <input
                  name="name"
                  type="text"
                  className="form-control register_custom_input"
                  id="name"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            <div className="col-md-12">
              <div className="mb-3">
                <label htmlFor="email" className="form-label login_label">
                  email <span className="yellow_star">*</span>
                </label>
                <input
                  name="email"
                  type="email"
                  className="form-control register_custom_input"
                  id="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            <div className="col-md-12">
              <div className="mb-3">
                <label htmlFor="phone" className="form-label login_label">
                  contact no <span className="yellow_star">*</span>
                </label>
                <input
                  name="phone"
                  type="text"
                  className="form-control register_custom_input"
                  id="phone"
                  placeholder="Contact no"
                  value={formData.phone}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            <div className="col-md-12">
              <div className="mb-3">
                <label htmlFor="comment" className="form-label login_label">
                  comment <span className="yellow_star">*</span>
                </label>
                <textarea
                  name="comment"
                  type="text"
                  className="form-control register_custom_input"
                  id="comment"
                  placeholder="Comment"
                  value={formData.comment}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            <div className="col-md-12">
              <div className="m-auto w-100 d-flex justify-content-center">
                <CustomButton
                  type="submit"
                  className="user_login_actionBth m-auto"
                  text="Confirm"
                />
              </div>
            </div>
          </div>
        </CustomModal>
      </form>
      <CustomModal
        show={feedback}
        close={() => setfeedback(false)}
        success={true}
        heading={modalText}
      />
    </>
  );
};

export default CartProducts;
